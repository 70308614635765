<template>
    <div class="nav">
        <div class="conxt">
            <TitleComponent :text='text'></TitleComponent>
            <div class="card">
                <div v-for="(item,index) in data" :key="index">
                    <img v-lazy="item.class" alt="" :key="item.class">
                    <p class="p1">{{item.title}}</p>
                    <p class="p2">{{item.text}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import TitleComponent from '../../../../components/titleComponent.vue'
const imgObj = require('../../../../assets/img/about/renyuan.png')
const imgObj2 = require('../../../../assets/img/about/qiye.png')
const imgObj3 = require('../../../../assets/img/about/disanfang.png')

const text = '我们的合作对象'
const data = [
    {
        class:imgObj,
        title:'保险从业人员',
        text:'打造自己的线上服务体系，丰富的展业工具协助您更好开拓业务、跟踪回访等业务需求'
    },
    {
        class:imgObj2,
        title:'保险企业合作',
        text:'企业可以通过我们系统销售、宣传自家保险产品，更可以定制开发、管理团队等企业需求'
    },
    {
        class:imgObj3,
        title:'第三方平台/经代合作',
        text:'开通对接第三方保险公司多渠道合作，可以渠道接入平台、报文管理/统一、开放共享API等，实现合作共赢'
    }
]
</script>

<style scoped>
.nav{
    position: relative;
    height: 809px;
    background-color: #F7F7F9;
}
.conxt{
    position: absolute;
    width: 1200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.card{
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}
.card img{
    width: 380px;
    height: 460px;
    vertical-align: bottom;
    z-index: -1;
}
/* .renyuan{
    width: 380px;
    height: 460px;
    background: url('../../../../assets/img/about/renyuan.png') no-repeat;
    background-size: 100% 100%;
}
.qiye{
    width: 380px;
    height: 460px;
    background: url('../../../../assets/img/about/qiye.png') no-repeat;
    background-size: 100% 100%;
}
.disanfang{
    width: 380px;
    height: 460px;
    background: url('../../../../assets/img/about/disanfang.png') no-repeat;
    background-size: 100% 100%;
} */
.p1{
    position: absolute;
    top: 20%;
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    margin: 30px 0 10px 30px;
}
.p2{
    position: absolute;
    top: 34%;
    width: 320px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F7F7F9;
    margin-left: 30px;
    line-height: 23px;
}
</style>