<template>
    <div class="nav">
        <div class="conxt">
            <div class="left">
                <TitleComponent :text='text' :Id='1'></TitleComponent>
                <div class="center"></div>
                <div>
                    <span>
                        做最贴心的服务平台，<br>凝聚一批专业，专注，友善，诚信的经纪人，<br>给保险消费者提供最优质的服务。
                    </span>
                </div>
            </div>
            <div class="right">
                <img v-lazy="imgObj" alt="">
            </div>
        </div>
    </div>
</template>

<script setup>
import TitleComponent from '../../../../components/titleComponent.vue'
const text = '我们的品牌理念<br><div style="font-size: 18px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #2A2A2A;">用保险链接一切，推动保险行业数字化转型</div>'
const imgObj = require('../../../../assets/img/about/pinpailinian@2x.png')
</script>

<style scoped>
.nav{
    position: relative;
    height: 699px;
    background-color: #f7f7f9;
}
.conxt{
    position: absolute;
    width: 1200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: space-between;
}
.left{
    padding-top: 131px;
}
.left span{
    font-size: 16px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #848484;
    line-height: 27px;
}
.center{
    width: 2px;
    height: 146px;
    background-color: rgba(0, 0, 0, .05);
    margin: 76px 0 47px 38px;
}
.right{
    margin-top: 60px;
    /* background: url('../../../../assets/img/about/pinpailinian.png') no-repeat;
    background-size: 100% 100%; */
}
.right img{
    width: 580px;
    height: 638px;
}
</style>