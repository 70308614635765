<template>
    <div class="nav">
        <div class="up">
            <TitleComponent :text='text'></TitleComponent>
        </div>
        <div class="down">
            <div class="conxt">
                <div class="card">
                    <!-- <img src="../../../../assets/img/logo.png" alt=""> -->
                    <img v-lazy="imgObj" alt="">
                    <div><span></span><p>{{p1}}</p></div>
                    <div><span></span><p>{{p2}}<b class="font">赋能数字化保险，成就企业价值</b>{{p3}}</p></div>
                </div>
                <!-- <img src="../../../../assets/img/about/qiyejieshao_bg.png" alt="" class="back"> -->
                <img v-lazy="imgObj2" alt="" class="back">
            </div>
        </div>
    </div>
</template>

<script setup>
import TitleComponent from '../../../../components/titleComponent.vue'
const text = '强大的创作团队带领您达到一个新的高度'
const p1 = '深圳市秦保科技有限公司（以下简称秦保科技）是一家以大数据为核心，探索保险领域互联网新模式的综合型新兴平台。团队的核心人员均来自知名的互联网及保险企业。'
const p2 = '秦保科技秉承着“'
const p3 = '”的经营理念，专注于保险科技的创新和赋能数字化保险服务。其研发的“鲁班云服”SaaS平台仅需2小时，即刻为客户打造出集个性化品牌、产品及展业功能为一体化的的专属智能保险云平台，有效协助客户提高工作效率、降低运营成本，从而实现利润的提升。'
const imgObj = require('../../../../assets/img/logo@2x.png')
const imgObj2 = require('../../../../assets/img/about/qiyejieshao_bg.png')
</script>

<style scoped>
.nav{
    position: relative;
    height: 830px;
    overflow: hidden;
}
.up{
    height: 139px;
    padding-top: 110px;
}
.down{
    height: 581px;
    background-color: #F7F7F9;
}
.conxt{
    width: 1200px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.conxt .card{
    width: 576px;
    height: 451px;
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 65px;
}
.conxt .card img{
    width: 159px;
    height: 45px;
    margin: 52px 0 20px 30px;
}
.conxt .card div{
    margin-bottom: 20px;
    margin-left: 30px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2A2A2A;
}
.conxt .card div span{
    display: inline-block;
    width: 8px;
    height: 8px;
    background: linear-gradient(180deg, #6D6D87 0%, #3B3B4F 100%);
    opacity: 0.68;
    border-radius: 8px;
    margin: 12px 15px 0 0;
}
.conxt .card div p{
    display: inline-block;
    width: 493px;
    vertical-align: top;
    line-height: 33px;

}
.conxt .back{
    position: absolute;
    left: 36%;
    top: 0;
    width: 1124px;
    height: 581px;
    z-index: -1;
}
</style>