<template>
    <div class="nav">
        <div class="conxt">
            <div class="left">
                <TitleComponent :text='text' :position='1'></TitleComponent>
                <p>请咨询客服电话或者给我们留言，我们会第一时间回应～</p>
                <div class="card">
                    <div><span></span>联系客服</div>
                    <div>
                        <!-- <img src="../../../../assets/img/about/time.png" alt=""> -->
                        <img v-lazy="imgObj" alt="">
                        工作日(09:00-19:00)
                    </div>
                    <div>
                        <!-- <img src="../../../../assets/img/about/mail.png" alt=""> -->
                        <img v-lazy="imgObj2" alt="">
                        vintoli@insurebao.com
                    </div>
                    <button @click="order">立即预约</button>
                    <div><span></span>联系地址</div>
                    <div>
                        <!-- <img src="../../../../assets/img/about/dizhi.png" alt=""> -->
                        <img v-lazy="imgObj3" alt="">
                        <p class="p1">深圳市南山区粤海街道高新区社区高新南一道013号赋安科技大楼B座</p>
                    </div>
                </div>
            </div>
            <div class="right">
                <img v-lazy="imgObj4" alt="">
            </div>
        </div>
    </div>
</template>

<script setup>
import TitleComponent from '../../../../components/titleComponent.vue'
const text = '联系我们'
const imgObj = require('../../../../assets/img/about/time@2x.png')
const imgObj2 = require('../../../../assets/img/about/mail@2x.png')
const imgObj3 = require('../../../../assets/img/about/dizhi@2x.png')
const imgObj4 = require('../../../../assets/img/about/ditu@2x.png')
const order = () => {
    window.location.href = 'https://www.wjx.cn/vm/rruZ6pb.aspx'
}
</script>

<style scoped>
.nav{
    position: relative;
    height: 684px;
}
.conxt{
    position: absolute;
    width: 1200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: space-between;
}
.left p{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #848484;
    margin: 10px 0 35px 0;
}
.card{
    width: 418px;
    height: 312px;
    background: #FFFFFF;
    box-shadow: 7px 6px 17px 0px rgba(18, 14, 75, 0.08);
    border-radius: 10px;
    padding: 20px 0 0 30px;
}
.card img{
    width: 18px;
    height: 18px;
}
.card span{
    display: inline-block;
    width: 6px;
    height: 18px;
    background: linear-gradient(136deg, #FE9F0C 0%, #FF7201 100%);
    border-radius: 3px;
    vertical-align: middle;
    margin-right: 10px;
}
.card button{
    width: 160px;
    height: 30px;
    background: linear-gradient(136deg, #FE9F0C 0%, #FF7201 100%);
    border-radius: 20px;
    border: 1px solid #DEDEDE;
    margin-bottom: 33px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
}
.card button:hover{
    background: linear-gradient(136deg, #F2B453 0%, #FD9644 100%);
}
.card img{
    vertical-align: middle;
    margin-right: 12px;
}
.card div:nth-of-type(1){
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
    margin-bottom: 20px;
}
.card div:nth-of-type(2){
    margin-bottom: 19px;
}
.card div:nth-of-type(3){
    margin-bottom: 15px;
}
.card div:nth-of-type(4){
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
    margin-bottom: 20px;
}
.card .p1{
    display: inline-block;
    width: 369px;
    vertical-align: top;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #414C6E;
    margin: 0;
}
.card div:nth-of-type(2),
.card div:nth-of-type(3),
.card div:nth-of-type(5){
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #414C6E;
}
.right{
    /* background: url('../../../../assets/img/about/ditu.png') no-repeat;
    background-size: 100% 100%; */
    margin-top: 30px;
}
.right img{
    width: 711px;
    height: 417px;
}
</style>