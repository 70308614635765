<template>
    <div class="nav">
        <div class="conxt">
            <TitleComponent :text='text'></TitleComponent>
            <div class="dev">
                <div class="card">
                    <div v-for="(item,index) in data" :key="index" class="prett">
                        <!-- <img :src="item.icon" alt=""> -->
                        <img v-lazy="item.icon" alt="" :key="item.icon">
                        <p class="p1">{{item.title}}</p>
                        <p class="p2">{{item.text}}</p>
                    </div>
                </div>
                <div class="image">
                    <img v-lazy="imgObj" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import TitleComponent from '../../../../components/titleComponent.vue'
import renxinghua from '../../../../assets/img/about/renxinghua@2x.png'
import hezuo from '../../../../assets/img/about/hezuo@2x.png'
import xiangying from '../../../../assets/img/about/xiangying@2x.png'
import xiaolv from '../../../../assets/img/about/xiaolv@2x.png'
const text = '与秦保合作的好处'
const imgObj = require('../../../../assets/img/about/haochu_img@2x.png')
const data =[
    {
        icon:renxinghua,
        title:'人性化',
        text:'开放的理念、年轻化的团队，与我们沟通更高效'
    },
    {
        icon:xiaolv,
        title:'从业务效率提升',
        text:'数字化业务模式，使业务员、企业工作更便捷、快速'
    },
    {
        icon:xiangying,
        title:'快速响应',
        text:'如果有问题，我们的团队7*24小时在线为您解答'
    },
    {
        icon:hezuo,
        title:'崭新的合作模式',
        text:'打破常规的保险模式，以大数据为核心的互联网新模式'
    }
]
</script>

<style scoped>
.nav{
    position: relative;
    height: 657px;
    padding-top: 110px;
}
.conxt{
    position: absolute;
    width: 1200px;
    left: 50%;
    transform: translateX(-50%);
}
.dev{
    display: flex;
    justify-content: space-between;
    /* margin-left: 50px; */
}
.card{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 590px;
    height: 398px;
    margin-top: 80px;
}
.prett{
    width: 280px;
    height: 184px;
    background: #FFFFFF;
    box-shadow: 4px 8px 10px 0px rgba(28, 50, 111, 0.05);
    border-radius: 10px;
}
.prett img{
    width: 40px;
    height: 40px;
    margin: 20px 0 15px 20px;
}
.prett .p1{
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
    margin: 0 0 10px 20px;
}
.prett .p2{
    width: 240px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #848484;
    margin-left: 20px;
    line-height: 23px;
}
.image{
    position: absolute;
    right: -63px;
    margin: -16px 0 0 61px;
    /* background: url('../../../../assets/img/about/haochu_img.png') no-repeat;
    background-size: 100% 100%; */
}
.image img{
    width: 609px;
    height: 614px;
}
</style>
